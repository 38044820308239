import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import BlogPost from "../components/blog-post";

export default function BlogPostTemplate({ data, pageContext, path, uri }) {
  const { frontmatter } = data.mdx;
  const node = data.mdx;

  return (
    <Layout
      nav="blog"
      pageTitle={frontmatter.title}
      pageDescription={frontmatter.description}
      pageCanonical={`${data.site.siteMetadata.siteUrl}${uri}`}
      relatedTo={{ slug: path, tags: frontmatter.tags || [] }}
    >
      <BlogPost node={node} />
    </Layout>
  );
}

export const blogPostQuery = graphql`
  query($id: String) {
    site {
      siteMetadata {
        siteUrl
      }
    }

    mdx(id: { eq: $id }) {
      id
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        twitter_card
        image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        tags
      }
      slug
      body
    }
  }
`;
